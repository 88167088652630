import { ColDef } from '@ag-grid-community/core';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  signal,
} from '@angular/core';
import { FilterType } from '@do/common-interfaces';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { BreadcrumbComponent } from '../breadcrumb/breadcrumb.component';
import { ButtonComponent } from '../core/button/button.component';
import { IconButtonComponent } from '../core/icon-button/icon-button.component';
import { FormBoxComponent } from '../form-box/form-box.component';
import {
  AutocompleteConfig,
  AutocompleteFieldComponent,
} from '../form-fields/autocomplete-field/autocomplete-field.component';
import { CheckFieldComponent } from '../form-fields/check-field/check-field.component';
import { DateFieldComponent } from '../form-fields/date-field/date-field.component';
import { NumericFieldComponent } from '../form-fields/numeric-field/numeric-field.component';
import { RadioFieldComponent } from '../form-fields/radio-field/radio-field.component';
import { SelectFieldComponent } from '../form-fields/select-field/select-field.component';
import { SwitchFieldComponent } from '../form-fields/switch-field/switch-field.component';
import { TextFieldComponent } from '../form-fields/text-field/text-field.component';
import { DataGridComponent } from '../grid/data-grid/data-grid.component';
import { PreviewBoxComponent } from '../preview-box/preview-box.component';
import { ToastService } from '../toast/toast.service';

@Component({
  styleUrl: './demo.component.scss',
  templateUrl: './demo.component.html',
  standalone: true,
  imports: [
    CommonModule,
    FormBoxComponent,
    PreviewBoxComponent,
    TextFieldComponent,
    CheckFieldComponent,
    DateFieldComponent,
    NumericFieldComponent,
    SwitchFieldComponent,
    RadioFieldComponent,
    SelectFieldComponent,
    AutocompleteFieldComponent,
    ButtonComponent,
    IconButtonComponent,
    BreadcrumbComponent,
    DataGridComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'do-ui-kit-demo',
})
export class UiKitDemoComponent {
  editMode = signal(true);

  modalService = inject(NgbModal);
  toastService = inject(ToastService);

  autocompleteConfig: AutocompleteConfig = {
    entity: 'user',
    decodeField: ['name'],
    codeField: 'id',
    tooltip: 'Sample info',
  };

  data = [
    { name: 'Andrea', surname: 'Corzani', id: 1 },
    { name: 'Elisa', surname: 'Pollarini', id: 2 },
  ];

  coldef: ColDef[] = [
    { field: 'name', type: 'text' },
    {
      field: 'surname',

      floatingFilter: true,
      filter: true,
      floatingFilterComponent: 'customGridFilter',
      floatingFilterComponentParams: {
        suppressFilterButton: true,
        filterType: FilterType.text,
        field: 'surname',
        entityName: '',
        hidden: false,
      },
    },
  ];
}
