import { HttpBackend, HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class LocaleFileHttpLoader extends TranslateLoader {
  httpClient = new HttpClient(inject(HttpBackend));

  public getTranslation(lang: string) {
    return this.httpClient.get(`/assets/i18n/${lang}.json`);
  }
}
