import { first, switchMap, tap } from 'rxjs';

import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';

import { AuthService } from '../auth.service';

export function isAuthenticatedGuard(): CanActivateFn {
  return (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const authService = inject(AuthService);
    const router = inject(Router);

    return authService.checkAuth().pipe(
      switchMap(() => authService.signedIn$),
      tap((isAuthenticated) => {
        if (!isAuthenticated) {
          authService.pushTmpUrl(state.url);
          router.navigateByUrl('auth/login');
        } else {
          const url = authService.popTmpUrl();
          if (url) {
            router.navigateByUrl(url);
          }
        }
      }),
      first()
    );
  };
}
