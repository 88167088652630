import { DragToSelectModule } from 'ngx-drag-to-select';
import { NgxPermissionsModule } from 'ngx-permissions';

import { provideHttpClient, withInterceptors } from '@angular/common/http';
import {
  ApplicationConfig,
  ErrorHandler,
  importProvidersFrom,
} from '@angular/core';
import { provideRouter } from '@angular/router';
import { ApiClientConfig, apiClientProviders } from '@do/app-api-client';
import {
  apiKeyInterceptor,
  tokenInterceptor,
  unauthorizedInterceptor,
} from '@do/app-auth';
import {
  CONFIRM_SERVICE_TOKEN,
  ROUTER_FACADE_TOKEN,
  TOAST_SERVICE_TOKEN,
} from '@do/app-common';
import {
  apiErrorInterceptor,
  ErrorConfig,
  GlobalErrorHandler,
} from '@do/app-error';
import { LocaleFileHttpLoader } from '@do/app-locale';
import { RouterFacade } from '@do/app-router';
import {
  ConfirmDialogService,
  ToastService,
  uiKitProviders,
} from '@do/app-ui-kit';
import { DefaultLanguage } from '@do/common-interfaces';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { environment } from '../environments/environment';
import { appRoutes } from './app.routes';

export const appConfig: ApplicationConfig = {
  providers: [
    ...uiKitProviders,
    provideRouter(appRoutes),
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    {
      provide: CONFIRM_SERVICE_TOKEN,
      useClass: ConfirmDialogService,
    },
    {
      provide: ROUTER_FACADE_TOKEN,
      useClass: RouterFacade,
    },
    {
      provide: TOAST_SERVICE_TOKEN,
      useClass: ToastService,
    },
    {
      provide: ApiClientConfig,
      useValue: {
        apiEndpoint: environment.apiBaseUrl,
        apiKey: environment.apiKey,
      },
    },
    {
      provide: ErrorConfig,
      useValue: {
        apiEndpoint: environment.apiBaseUrl,
      },
    },
    // httpInterceptorProviders,

    importProvidersFrom(NgxPermissionsModule.forRoot()),
    importProvidersFrom(
      TranslateModule.forRoot({
        loader: { provide: TranslateLoader, useClass: LocaleFileHttpLoader },
        defaultLanguage: DefaultLanguage,
      })
    ),
    provideHttpClient(
      withInterceptors([
        unauthorizedInterceptor,
        tokenInterceptor,
        apiKeyInterceptor,
        apiErrorInterceptor,
      ])
    ),
    importProvidersFrom(DragToSelectModule.forRoot()),
    ...apiClientProviders,
  ],
};
