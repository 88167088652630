import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ButtonComponent } from '@do/app-ui-kit';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

// eslint-disable-next-line @nx/enforce-module-boundaries
import * as packageJson from '../../../../../package.json';
import { environment } from '../../environments/environment';

@Component({
  standalone: true,
  selector: 'web-platform-about-popup',
  imports: [CommonModule, ButtonComponent, TranslateModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: `
  `,
  template: `
    <div class="modal-header">
      <span class="modal-title fw-bold" translate>{{ title }}</span>

      <span
        class="material-symbols-outlined text-primary cursor-pointer"
        (click)="cancel()"
      >
        cancel
      </span>
    </div>
    <div class="modal-body px-4 pb-4">
      <div class="d-flex align-items-center">
        <div class="logo col-4">
          <img class="w-100" src="assets/images/logo.png" />
        </div>
        <div class="ms-auto">
          <div class="version fw-semibold text-end">
            <div>
              <span class="fw-normal me-3" translate>App version:</span>
              {{ version }}
            </div>
            <div>
              <span class="fw-normal me-3" translate>Api version: </span>
              {{ apiVersion()?.apiVersion }}
            </div>
            <div>
              <span class="fw-normal me-3" translate>Database version:</span>
              {{ apiVersion()?.dbVersion }}
            </div>
          </div>
        </div>
      </div>

      <div class="fs-0875 mt-4 text-end">
        <span class="fw-normal"
          >Copyright 2024 - {{ currentYear }} Dewert Okin.
          <span translate>All rights reserved.</span></span
        >
      </div>
    </div>
  `,
})
export class AboutPopupContainer {
  httpClient = inject(HttpClient);
  activeModal = inject(NgbActiveModal);
  title = '';

  version = packageJson.version;

  currentYear = new Date().getFullYear();

  translateService = inject(TranslateService);

  apiVersion = toSignal(
    this.httpClient.get<{ apiVersion: string; dbVersion: string }>(
      environment.apiBaseUrl + '/api-status'
    )
  );

  cancel() {
    this.activeModal.close(null);
  }
}
