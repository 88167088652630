import { ErrorHandler, Injectable, Injector } from '@angular/core';

import { ErrorStore } from './+state/error.state';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private injector: Injector) {}

  handleError(error: Error) {
    const store = this.injector.get(ErrorStore);

    if (
      error.message.startsWith('Failed to fetch dynamically imported module')
    ) {
      store.addLazyNavigationErrorMsg(error);
    } else {
      store.addError(error);
    }
  }
}
