import { NgxPermissionsService } from 'ngx-permissions';
import { Subject } from 'rxjs';

import { Location } from '@angular/common';
import { inject, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
    BreadcrumbItem, PERMISSION_CREATE, PERMISSION_DELETE, PERMISSION_READ, PERMISSION_UPDATE,
    RouterFacadeInterface
} from '@do/app-common';
import { BaseDto } from '@do/common-dto';
import { FilterValue } from '@do/common-interfaces';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import {
    BroadcastChannelEventType, EDIT_MODE_PARAM, FIND_MODE_PARAM, MODAL_LIST_MODE_PARAM,
    VIEW_MODE_PARAM
} from './constants';
import { ModalWrapperComponent } from './modal-wrapper.component';
import { RouterStore } from './router.store';

@Injectable({ providedIn: 'root' })
export class RouterFacade implements RouterFacadeInterface {
  private routerStore = inject(RouterStore);
  private router = inject(Router);
  private location = inject(Location);
  private modalService = inject(NgbModal);
  private permissionsService = inject(NgxPermissionsService);

  isViewOnlyMode = this.routerStore.isViewOnlyMode;
  isFindMode = this.routerStore.isFindMode;
  isEditMode = this.routerStore.isEditMode;
  // isAddMode = this.routerStore.isAddMode;
  isModalListMode = this.routerStore.isModalListMode;
  channelId = this.routerStore.channelId;
  queryParams = this.routerStore.queryParams;
  params = this.routerStore.params;
  url = this.routerStore.url;

  reloadIframe = new Subject<void>();

  navigateRelative(
    commands: any[],
    replaceUrl = false,
    relativeTo?: ActivatedRoute | null
  ): Promise<boolean> {
    return this.router.navigate(commands, {
      relativeTo:
        relativeTo === undefined ? this.getCurrentRoute() : relativeTo,
      queryParamsHandling: 'preserve',
      replaceUrl,
    });
  }

  back() {
    this.location.back();
  }

  notifySelectedItemToOpener(item: BaseDto) {
    return this.sendAsyncMessage({
      type: BroadcastChannelEventType.Select,
      item,
    });
  }

  notifySavedItemToOpener(item: BaseDto) {
    return this.sendAsyncMessage({
      type: BroadcastChannelEventType.Save,
      item,
    });
  }

  notifyBreadcrumbChangedToOpener(breadcrumb: BreadcrumbItem[]) {
    return this.sendAsyncMessage({
      type: BroadcastChannelEventType.Breadcrumb,
      item: breadcrumb,
    });
  }

  notifyDeletedItemToOpener(id: string) {
    return this.sendAsyncMessage({
      type: BroadcastChannelEventType.Delete,
      item: { id },
    });
  }

  addNewEntity(entity: string, queryParams?: string, customTitle?: string) {
    return this.openModal(
      EDIT_MODE_PARAM,
      entity.replace('_', '-'),
      '0',
      queryParams,
      customTitle
    );
  }

  canViewEntity(entityName: string): boolean {
    return (
      !!this.permissionsService.getPermission(
        `${PERMISSION_READ}_${entityName}`
      ) &&
      !this.isViewOnlyMode() &&
      !this.isFindMode()
    );
  }

  canAddEntity(entityName: string): boolean {
    return (
      !!this.permissionsService.getPermission(
        `${PERMISSION_CREATE}_${entityName}`
      ) &&
      !this.isViewOnlyMode() &&
      !this.isFindMode()
    );
  }

  canDeleteEntity(entityName: string): boolean {
    return (
      !!this.permissionsService.getPermission(
        `${PERMISSION_DELETE}_${entityName}`
      ) &&
      !this.isViewOnlyMode() &&
      !this.isFindMode()
    );
  }

  canEditEntity(entityName: string): boolean {
    return (
      !!this.permissionsService.getPermission(
        `${PERMISSION_UPDATE}_${entityName}`
      ) &&
      !this.isViewOnlyMode() &&
      !this.isFindMode()
    );
  }

  editEntityDetail(
    entity: string,
    id: string,
    queryParams?: string,
    customTitle?: string
  ) {
    return this.openModal(
      EDIT_MODE_PARAM,
      entity,
      id,
      queryParams,
      customTitle
    );
  }

  openEntityDetail(
    entity: string,
    id: string,
    edit: boolean,
    queryParams?: string,
    customTitle?: string
  ) {
    return this.openModal(
      edit ? EDIT_MODE_PARAM : VIEW_MODE_PARAM,
      entity.replace('_', '-'),
      id,
      queryParams,
      customTitle
    );
  }

  searchEntity(entity: string, filters?: FilterValue[]) {
    let queryParams = '';

    if (filters) {
      queryParams = queryParams + 'filters=' + btoa(JSON.stringify(filters));
    }

    return this.openModal(FIND_MODE_PARAM, entity, undefined, queryParams);
  }

  openEntityList<T>(
    entity: string,
    param?: T,
    filters?: FilterValue[],
    title?: string,
    fullscreen = false
  ) {
    const filtersQp = filters ? 'filters=' + btoa(JSON.stringify(filters)) : '';

    const entityQp = param ? 'param=' + btoa(JSON.stringify(param)) : '';

    return this.openModal(
      MODAL_LIST_MODE_PARAM,
      entity,
      undefined,
      [filtersQp, entityQp].join('&'),
      title,
      fullscreen ? 'fullscreen-modal' : ''
    );
  }

  async viewEntityDetail(entity: string, id: string, customTitle?: string) {
    try {
      await this.openModal(VIEW_MODE_PARAM, entity, id, undefined, customTitle);
    } catch {
      /* empty */
    }
  }

  private sendAsyncMessage(message: any) {
    return new Promise<void>((resolve, reject) => {
      const channelId = this.channelId();
      if (channelId) {
        const bc = new BroadcastChannel(channelId);
        bc.postMessage(message);

        const timeout = setTimeout(() => {
          bc.close();
          reject('Channel communication: timeout expired');
        }, 3000);

        bc.onmessage = (ev) => {
          if (ev.data?.type === BroadcastChannelEventType.Ack) {
            bc.close();
            clearTimeout(timeout);
            resolve();
          }
        };
      } else {
        resolve();
      }
    });
  }

  private getCurrentRoute() {
    let route = this.router.routerState.root;
    while (route.firstChild) {
      route = route.firstChild;
    }

    return route;
  }

  private openModal(
    modeParam: string,
    entity: string,
    id?: string,
    queryParams?: string,
    customTitle?: string,
    customClass?: string
  ) {
    const modal = this.modalService.open(ModalWrapperComponent, {
      size: 'xl',
      windowClass: 'iframe-modal',
      centered: true,
      modalDialogClass: customClass,
    });

    const modalInstance = modal.componentInstance as ModalWrapperComponent;

    modalInstance.entity = entity;
    modalInstance.queryParams = queryParams;
    modalInstance.id = id || '';
    modalInstance.mode = modeParam;
    modalInstance.title = customTitle || '';

    return modal.result as Promise<any>;
  }
}
