import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
  signal,
} from '@angular/core';

@Component({
  selector: 'do-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  standalone: true,
  imports: [CommonModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoaderComponent implements OnInit, OnDestroy {
  @Input()
  show?: boolean = false;

  @Input()
  messages: string[] = [];

  messageIndex = 0;

  message = signal('');

  private messageLoop?: number;

  private setMessage() {
    if (this.messages.length === 0) this.message.set('');
    this.message.set(this.messages[this.messageIndex % this.messages.length]);
  }

  ngOnInit(): void {
    this.setMessage();
    this.messageLoop = window.setInterval(() => {
      this.messageIndex++;
      this.setMessage();
    }, 3000);
  }

  ngOnDestroy(): void {
    clearInterval(this.messageLoop);
  }

  constructor() {}
}
