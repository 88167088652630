import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  input,
  Output,
} from '@angular/core';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { MenuItem } from '@do/app-common';
import { UserDto } from '@do/common-dto';
import { NgbPopover, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

import { ButtonComponent } from '../core/button/button.component';
import { SidebarService } from './sidebar.service';

@Component({
  standalone: true,
  selector: 'do-sidebar',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    RouterLink,
    RouterLinkActive,
    ButtonComponent,
    NgbPopover,
    NgbTooltip,
    TranslateModule,
  ],
  animations: [
    trigger('sidebarTrigger', [
      state('open', style({ width: '230px' })),
      state('close', style({ width: '58px' })),
      transition('open => close', [animate('300ms ease-in')]),
      transition('close => open', [animate('300ms ease-out')]),
    ]),
  ],
  styleUrl: './sidebar.component.scss',
  template: `
    <nav
      [@sidebarTrigger]="sidebarService.showSidebar() ? 'open' : 'close'"
      (@sidebarTrigger.done)="onAnimationCompleted()"
      class="d-flex flex-column flex-shrink-0 h-100 shadow text-light"
      [class.closed]="!sidebarService.showSidebar()"
    >
      <a
        [routerLink]="['/']"
        class="w-100 mt-3 mb-md-0 text-decoration-none d-block logo-wrapper px-1"
      >
        <div class="logo"></div>
      </a>
      <ul class="nav nav-pills flex-column mb-auto mt-5">
        @for (item of menuItems(); track item.link) { @if (item.isTitle) {
        <li
          [ngbTooltip]="item.label"
          class="text-light fw-semibold ms-2 mt-5 mb-1 text-truncate w-100 flex-shrink-0"
        >
          {{ item.label | translate }}
        </li>
        } @else {
        <li class="nav-item">
          <a
            class="nav-link"
            [routerLink]="item.link"
            [ngbTooltip]="item.label | translate"
            [routerLinkActive]="['active']"
            [routerLinkActiveOptions]="{ exact: item.link === '' }"
          >
            <span class="material-symbols-outlined me-3">
              {{ item.icon }}
            </span>
            <span class="hide-on-close">{{ item.label | translate }}</span></a
          >
        </li>
        } }
      </ul>
      <hr />
      <div
        [ngbPopover]="popContent"
        class="text-white text-small d-flex align-items-center"
      >
        <div class="px-3">
          <span class="material-symbols-outlined cursor-pointer">
            account_circle
          </span>
        </div>
        <div class="overflow-hidden hide-on-close">
          <div class="fw-light text-truncate">
            {{ loggedUser()?.name }} {{ loggedUser()?.surname }}
          </div>
          <div class="fw-semibold text-truncate">{{ loggedUser()?.email }}</div>
        </div>
      </div>
      <hr />
      <div class="text-white text-small d-flex align-items-center">
        <div class="px-3">
          <span
            class="material-symbols-outlined cursor-pointer"
            (click)="about.emit()"
          >
            info
          </span>
        </div>
        <div class="overflow-hidden hide-on-close">
          <div class="fw-light text-truncate"></div>
          <div class="fw-semibold text-truncate">{{ title() }}</div>
        </div>
      </div>
      <hr />
      <div>
        <span
          class="toggle-sidebar-icon material-symbols-outlined text-white ms-3 cursor-pointer"
          [class.rotate]="!sidebarService.showSidebar()"
          (click)="sidebarService.toggleSidebar()"
          >menu_open</span
        >
      </div>
    </nav>

    <ng-template #popContent
      ><div class="text-truncate">
        {{ loggedUser()?.name }} {{ loggedUser()?.surname }}
      </div>
      <div class="fw-semibold text-truncate">
        {{ loggedUser()?.email }}
      </div>

      <do-button
        class="d-flex mt-1"
        [text]="'Profile' | translate"
        icon="account_circle"
        cssClasses="btn-secondary btn-sm flex-grow-1"
        (click)="profile.emit()"
      ></do-button>

      <do-button
        class="d-flex mt-1"
        [text]="'Logout' | translate"
        icon="logout"
        cssClasses="btn-secondary btn-sm flex-grow-1"
        (click)="logout.emit()"
      ></do-button>
    </ng-template>
  `,
})
export class SidebarComponent {
  loggedUser = input<UserDto | null>(null);

  menuItems = input([] as MenuItem[]);

  title = input('');

  sidebarService = inject(SidebarService);
  router = inject(Router);

  @Output()
  logout = new EventEmitter<void>();

  @Output()
  profile = new EventEmitter<void>();

  @Output()
  about = new EventEmitter<void>();

  onAnimationCompleted() {
    this.sidebarService.animationCompleted.emit();
  }
}
