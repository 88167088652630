import { Injectable } from '@angular/core';
import { ConfirmServiceInterface } from '@do/app-common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

import { ConfirmDialogComponent } from './confirm-dialog.component';

@Injectable()
export class ConfirmDialogService implements ConfirmServiceInterface {
  constructor(
    private modal: NgbModal,
    private translateService: TranslateService
  ) {}

  public confirm(
    message: string = this.translateService.instant('Are you sure?'),
    title: string = '',
    cancelText: string = this.translateService.instant('Cancel'),
    okText: string = this.translateService.instant('Yes')
  ) {
    const dialogRef = this.modal.open(ConfirmDialogComponent, {
      size: 'md',
    });

    const instance = dialogRef.componentInstance as ConfirmDialogComponent;

    instance.message = message;
    instance.title = title;
    instance.cancelText = cancelText;
    instance.okText = okText;

    return dialogRef.result as Promise<boolean>;
  }

  deleteConfirm(): Promise<boolean> {
    return this.confirm(
      this.translateService.instant('Confirm you want to delete this item?')
    );
  }
}
