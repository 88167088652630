import { provideToastr } from 'ngx-toastr';

import { EnvironmentProviders, Provider } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';

export const uiKitProviders: Array<Provider | EnvironmentProviders> = [
  provideAnimations(), // required animations providers
  provideToastr({
    positionClass: 'toast-top-right',
    tapToDismiss: true,
  }),
];
