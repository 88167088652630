import { EnvironmentProviders, Provider } from '@angular/core';

import { CinemaChainApiClient } from './entities/cinema-chain.api-client';
import { CinemaRoomApiClient } from './entities/cinema-room.api-client';
import { CinemaApiClient } from './entities/cinema.api-client';
import { CustomerApiClient } from './entities/customer.api-client';
import { MasterControlUnitApiClient } from './entities/master-control-unit.api-client';
import { PermissionApiClient } from './entities/permission.api-client';
import { RoleApiClient } from './entities/role.api-client';
import { SeatControlUnitApiClient } from './entities/seat-control-unit.api-client';
import { UserApiClient } from './entities/user.api-client';

export const apiClientProviders: Array<Provider | EnvironmentProviders> = [
  {
    provide: 'userApiClient',
    useClass: UserApiClient,
  },
  {
    provide: 'roleApiClient',
    useClass: RoleApiClient,
  },
  {
    provide: 'customerApiClient',
    useClass: CustomerApiClient,
  },
  {
    provide: 'cinema_chainApiClient',
    useClass: CinemaChainApiClient,
  },
  {
    provide: 'cinemaApiClient',
    useClass: CinemaApiClient,
  },
  {
    provide: 'cinema_roomApiClient',
    useClass: CinemaRoomApiClient,
  },
  {
    provide: 'master_control_unitApiClient',
    useClass: MasterControlUnitApiClient,
  },
  {
    provide: 'seat_control_unitApiClient',
    useClass: SeatControlUnitApiClient,
  },
  {
    provide: 'permissionApiClient',
    useClass: PermissionApiClient,
  },
];
