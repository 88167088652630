import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { ButtonComponent } from '../core/button/button.component';
import { IconButtonComponent } from '../core/icon-button/icon-button.component';

@Component({
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, IconButtonComponent, ButtonComponent],
})
export class ConfirmDialogComponent {
  @Input()
  title = '';

  @Input()
  message!: string;

  @Input()
  okText!: string;

  @Input()
  cancelText!: string;

  constructor(public activeModal: NgbActiveModal) {}

  cancel() {
    this.activeModal.close(false);
  }

  confirm() {
    this.activeModal.close(true);
  }
}
