import { catchError, EMPTY, Observable, switchMap, throwError } from 'rxjs';

import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandlerFn,
  HttpInterceptorFn,
  HttpRequest,
} from '@angular/common/http';
import { inject } from '@angular/core';

import { AuthStore } from './+state/auth.store';
import { AuthService } from './auth.service';

export const unauthorizedInterceptor: HttpInterceptorFn = (
  request: HttpRequest<unknown>,
  next: HttpHandlerFn
): Observable<HttpEvent<unknown>> => {
  const authService = inject(AuthService);
  const authStore = inject(AuthStore);

  return next(request).pipe(
    catchError((error: HttpErrorResponse) => {
      if (
        error instanceof HttpErrorResponse &&
        error.status === 401 &&
        !(
          request.url.includes('auth/sign-in') ||
          request.url.includes('auth/refresh')
        ) // <- this will avoid an infinite loop when the accessToken expires.
      ) {
        return authService.refreshTokens().pipe(
          switchMap((refreshResult) => {
            // assuming that tokenService.refreshToken() will return { accessToken: 'myNewAccessToken', refreshToken: 'myNewRefreshToken'}
            return next(
              request.clone({
                headers: request.headers.set(
                  'Authorization',
                  `Bearer ${refreshResult.accessToken}`
                ),
              })
            );
          }),
          catchError((error) => {
            if (error.status === 401 && error instanceof HttpErrorResponse) {
              authStore.sessionExpired(error.error.message || undefined);
            }
            return EMPTY;
          })
        );
      }

      return throwError(() => error);
    })
  );
};
