import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  inject,
  ViewChild,
} from '@angular/core';
import { RouterModule } from '@angular/router';
import { LoaderStore } from '@do/app-loader';
import { LoaderComponent } from '@do/app-ui-kit';
import { BaseDto } from '@do/common-dto';
import { NgbModalConfig, NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Component({
  standalone: true,
  imports: [RouterModule, LoaderComponent],
  selector: 'web-platform-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  providers: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  @ViewChild('content', { static: true })
  content?: ElementRef;

  loaderStore = inject(LoaderStore);

  translateService = inject(TranslateService);

  dto: BaseDto = {
    id: '',
  };

  constructor(config: NgbTooltipConfig, modalConfig: NgbModalConfig) {
    config.openDelay = 500;
    modalConfig.backdrop = 'static';
  }
}
