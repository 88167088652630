import { Route } from '@angular/router';
import {
  isAuthenticatedGuard,
  loggedUserGuard,
  routes as authRoutes,
} from '@do/app-auth';
import { UiKitDemoComponent } from '@do/app-ui-kit';

import IFrameComponent from './iframe/iframe.component';
import LayoutComponent from './layout/layout.component';
import ModalComponent from './modal/modal.component';

const featureRoutes: Route[] = [
  {
    path: '',
    pathMatch: 'full',
    canActivate: [loggedUserGuard()],
    loadChildren: () =>
      import('@do/app-features-dashboard').then((m) => m.dashboardRoutes),
  },
  {
    path: 'user',
    canActivate: [loggedUserGuard()],
    loadChildren: () =>
      import('@do/app-features-user').then((m) => m.userRoutes),
  },
  {
    path: 'role',
    canActivate: [loggedUserGuard()],
    loadChildren: () =>
      import('@do/app-features-role').then((m) => m.roleRoutes),
  },
  {
    path: 'customer',
    canActivate: [loggedUserGuard()],
    loadChildren: () =>
      import('@do/app-features-customer').then((m) => m.customerRoutes),
  },
  {
    path: 'cinema-chain',
    canActivate: [loggedUserGuard()],
    loadChildren: () =>
      import('@do/app-features-cinema-chain').then((m) => m.cinemaChainRoutes),
  },
  {
    path: 'cinema',
    canActivate: [loggedUserGuard()],
    loadChildren: () =>
      import('@do/app-features-cinema').then((m) => m.cinemaRoutes),
  },
  {
    path: 'cinema-room',
    canActivate: [loggedUserGuard()],
    loadChildren: () =>
      import('@do/app-features-cinema-room').then((m) => m.cinemaRoomRoutes),
  },
  {
    path: 'system',
    canActivate: [loggedUserGuard()],
    loadChildren: () =>
      import('@do/app-features-system').then((m) => m.systemRoutes),
  },
  {
    path: 'master-control-unit',
    canActivate: [loggedUserGuard()],
    loadChildren: () =>
      import('@do/app-features-master-control-unit').then(
        (m) => m.masterControlUnitRoutes
      ),
  },
  {
    path: 'overview',
    canActivate: [loggedUserGuard()],
    loadChildren: () =>
      import('@do/app-features-cinema-overview').then(
        (m) => m.cinemaOverviewRoutes
      ),
  },
  {
    path: 'ui-kit',
    canActivate: [loggedUserGuard()],
    component: UiKitDemoComponent,
  },
];

export const appRoutes: Route[] = [
  {
    path: '',
    canActivate: [isAuthenticatedGuard()],
    component: LayoutComponent,
    children: featureRoutes,
  },
  {
    path: 'iframe',
    canActivate: [isAuthenticatedGuard()],
    component: IFrameComponent,
    children: featureRoutes,
  },
  {
    path: 'modal',
    canActivate: [isAuthenticatedGuard()],
    component: ModalComponent,
    children: featureRoutes,
  },
  {
    path: 'auth',
    pathMatch: 'prefix',
    children: authRoutes,
  },
];
