<div class="modal-header" *ngIf="title">
  <div class="d-flex align-items-center">
    <h4 class="modal-title" id="modal-basic-title">
      {{ title }}
    </h4>
  </div>
  <do-icon-button
    icon="close"
    cssClasses="text-primary hover-bg-primary"
    (clicked)="cancel()"
  >
  </do-icon-button>
</div>
<div class="modal-body">
  <div class="message" [innerHTML]="message"></div>
</div>
<div class="modal-footer">
  <do-button
    class="me-2"
    *ngIf="cancelText"
    [cssClasses]="'btn-outline-primary'"
    [text]="cancelText"
    (clicked)="cancel()"
  ></do-button>
  <do-button
    [cssClasses]="'btn-primary'"
    [text]="okText"
    (clicked)="confirm()"
  ></do-button>
</div>
