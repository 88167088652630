{
  "name": "@do/web-platform",
  "version": "1.0.32",
  "license": "MIT",
  "scripts": {
    "start:api": "npx env-cmd -f ./apps/api/.env --fallback nx serve api",
    "start:api:dev": "npx env-cmd -f ./apps/api/.dev.env --fallback nx serve api",
    "start": "nx serve frontend --proxy-config apps/frontend/proxy-local.conf.json",
    "start:proxytest": "nx serve frontend --configuration=test --proxy-config apps/frontend/proxy-test.conf.json",
    "start:proxyprod": "nx serve frontend --configuration=production --proxy-config apps/frontend/proxy-prod.conf.json",
    "build:api": "nx build api --generatePackageJson",
    "build:frontend": "nx build frontend --stats-json",
    "build:frontend:dev": "nx build frontend --configuration=development",
    "build:frontend:prod": "nx build frontend --configuration=production",
    "extract:en": "ngx-translate-extract --key-as-default-value --input ./libs ./apps/frontend --output ./apps/frontend/src/assets/i18n/en.json --clean --sort --format json",
    "extract:other": "ngx-translate-extract --input ./libs ./apps/frontend --output ./apps/frontend/src/assets/i18n/it.json --clean --sort --format json",
    "docker:build:deps:local": "docker build -f ./tools/devops/provision/api/local/deps/Dockerfile . -t do-api-deps",
    "docker:build:api:local": "docker build  --no-cache -f ./tools/devops/provision/api/local/Dockerfile . -t do-api",
    "docker:build:api": "docker build  --no-cache -f ./tools/devops/provision/api/Dockerfile . -t do-api",
    "docker:compose:local-all:win": "cd tools\\devops\\provision\\local-all && docker-compose up -d",
    "docker:compose:local-all:unix": "cd ./tools/devops/provision/local-all && docker-compose up -d",
    "deploy:login": "aws ecr get-login-password --profile dewert-okin --region us-east-1 | docker login --username AWS --password-stdin 654654145781.dkr.ecr.us-east-1.amazonaws.com",
    "deploy:login:prod": "aws ecr get-login-password --profile dewert-okin-prod --region eu-west-1 | docker login --username AWS --password-stdin 975049949261.dkr.ecr.eu-west-1.amazonaws.com",
    "deploy:backend:dev": "docker tag do-api:latest 654654145781.dkr.ecr.us-east-1.amazonaws.com/do-dev-ecr-backend && docker push 654654145781.dkr.ecr.us-east-1.amazonaws.com/do-dev-ecr-backend:latest",
    "deploy:backend:prod": "docker tag do-api:latest 975049949261.dkr.ecr.eu-west-1.amazonaws.com/do-prod-ecr-backend && docker push 975049949261.dkr.ecr.eu-west-1.amazonaws.com/do-prod-ecr-backend:latest",
    "deploy:elb-version:dev": "aws elasticbeanstalk create-application-version --profile dewert-okin --application-name do-dev-eb-backend-docker --version-label deploy-from-local-8 --source-bundle S3Bucket=\"do-dev-s3-backend\",S3Key=\"backend-platform-docker-config.zip\"",
    "deploy:elb-version:prod": "aws elasticbeanstalk create-application-version --profile dewert-okin-prod --application-name do-prod-eb-backend-docker --version-label deploy-from-local-10 --source-bundle S3Bucket=\"do-prod-s3-backend\",S3Key=\"backend-platform-docker-config.zip\"",
    "deploy:elb-env:dev": "aws elasticbeanstalk update-environment --profile dewert-okin --application-name do-dev-eb-backend-docker --environment-name do-dev-eb-backend-docker-env --version-label deploy-from-local-8",
    "deploy:elb-env:prod": "aws elasticbeanstalk update-environment --profile dewert-okin-prod --application-name do-prod-eb-backend-docker --environment-name do-prod-eb-backend-docker-env --version-label deploy-from-local-10",
    "deploy:frontend:dev": "aws s3 --delete sync ./dist/apps/frontend/browser s3://do-dev-s3-frontend --profile dewert-okin && aws cloudfront create-invalidation --distribution-id E11WKSS2J9F9WB --paths /* --profile dewert-okin",
    "deploy:frontend:prod": "aws s3 --delete sync ./dist/apps/frontend/browser s3://do-prod-s3-frontend --profile dewert-okin-prod && aws cloudfront create-invalidation --distribution-id E21KWUO6U8L8DL --paths /* --profile dewert-okin-prod"
  },
  "private": true,
  "dependencies": {
    "@ag-grid-community/angular": "^31.0.3",
    "@ag-grid-community/client-side-row-model": "^31.0.3",
    "@ag-grid-community/core": "^31.0.3",
    "@ag-grid-community/styles": "^31.0.3",
    "@ag-grid-enterprise/clipboard": "^31.0.3",
    "@ag-grid-enterprise/column-tool-panel": "^31.0.3",
    "@ag-grid-enterprise/row-grouping": "^31.0.3",
    "@ag-grid-enterprise/server-side-row-model": "^31.0.3",
    "@angular-slider/ngx-slider": "^17.0.1",
    "@angular/animations": "17.3.5",
    "@angular/common": "17.3.5",
    "@angular/compiler": "17.3.5",
    "@angular/core": "17.3.5",
    "@angular/forms": "17.3.5",
    "@angular/platform-browser": "17.3.5",
    "@angular/platform-browser-dynamic": "17.3.5",
    "@angular/router": "17.3.5",
    "@auth0/angular-jwt": "^5.2.0",
    "@automapper/classes": "^8.8.1",
    "@automapper/core": "^8.8.1",
    "@automapper/nestjs": "^8.8.1",
    "@aws-sdk/client-iot": "^3.529.1",
    "@aws-sdk/client-iot-data-plane": "^3.529.1",
    "@aws-sdk/client-s3": "^3.550.0",
    "@aws-sdk/client-ses": "^3.521.0",
    "@aws-sdk/s3-request-presigner": "^3.552.0",
    "@nestjs/common": "^10.0.2",
    "@nestjs/config": "^3.1.1",
    "@nestjs/core": "^10.0.2",
    "@nestjs/jwt": "^10.2.0",
    "@nestjs/passport": "^10.0.3",
    "@nestjs/platform-express": "^10.0.2",
    "@nestjs/schedule": "^4.0.1",
    "@nestjs/swagger": "^7.2.0",
    "@nestjs/typeorm": "^10.0.1",
    "@ng-bootstrap/ng-bootstrap": "^16.0.0",
    "@ng-select/ng-select": "^12.0.6",
    "@ngrx/operators": "^17.1.0",
    "@ngrx/signals": "^17.1.0",
    "@ngx-translate/core": "^15.0.0",
    "@ngx-translate/http-loader": "^8.0.0",
    "@nx/angular": "18.3.1",
    "@nx/devkit": "18.3.1",
    "@nx/plugin": "18.3.1",
    "@swc/helpers": "~0.5.2",
    "argon2": "^0.31.2",
    "axios": "1.6.0",
    "bootstrap": "^5.3.2",
    "class-transformer": "^0.5.1",
    "class-validator": "^0.14.1",
    "cookie-parser": "^1.4.6",
    "helmet": "^7.1.0",
    "inputmask": "^5.0.3",
    "lodash": "^4.17.21",
    "moment": "^2.30.1",
    "mysql2": "^3.8.0",
    "nest-winston": "^1.9.4",
    "ngx-color-picker": "^16.0.0",
    "ngx-drag-to-select": "^5.0.1",
    "ngx-permissions": "^16.0.1",
    "ngx-toastr": "^18.0.0",
    "passport-headerapikey": "^1.2.2",
    "passport-jwt": "^4.0.1",
    "reflect-metadata": "^0.1.13",
    "rxjs": "~7.8.0",
    "tslib": "^2.3.0",
    "typeorm": "^0.3.19",
    "uuid": "^9.0.1",
    "winston": "^3.11.0",
    "winston-daily-rotate-file": "^4.7.1",
    "zone.js": "~0.14.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "17.3.5",
    "@angular-devkit/core": "17.3.5",
    "@angular-devkit/schematics": "17.3.5",
    "@angular-eslint/eslint-plugin": "17.3.0",
    "@angular-eslint/eslint-plugin-template": "17.3.0",
    "@angular-eslint/template-parser": "17.3.0",
    "@angular/cli": "~17.3.0",
    "@angular/compiler-cli": "17.3.5",
    "@angular/language-service": "17.3.5",
    "@nestjs/schematics": "^10.0.1",
    "@nestjs/testing": "^10.0.2",
    "@nx/cypress": "18.3.1",
    "@nx/eslint": "18.3.1",
    "@nx/eslint-plugin": "18.3.1",
    "@nx/jest": "18.3.1",
    "@nx/js": "18.3.1",
    "@nx/nest": "18.3.1",
    "@nx/node": "18.3.1",
    "@nx/web": "18.3.1",
    "@nx/webpack": "18.3.1",
    "@nx/workspace": "18.3.1",
    "@schematics/angular": "17.3.5",
    "@swc-node/register": "1.8.0",
    "@swc/cli": "~0.1.62",
    "@swc/core": "~1.3.85",
    "@types/cookie-parser": "^1.4.6",
    "@types/inputmask": "^5.0.2",
    "@types/jest": "^29.4.0",
    "@types/lodash": "^4.14.202",
    "@types/multer": "^1.4.11",
    "@types/node": "18.16.9",
    "@types/passport-jwt": "^4.0.0",
    "@types/uuid": "^9.0.8",
    "@typescript-eslint/eslint-plugin": "7.3.0",
    "@typescript-eslint/parser": "7.3.0",
    "@vendure/ngx-translate-extract": "^9.1.0",
    "autoprefixer": "^10.4.0",
    "cypress": "^13.6.6",
    "eslint": "8.57.0",
    "eslint-config-prettier": "^9.0.0",
    "eslint-plugin-cypress": "^2.13.4",
    "jest": "^29.4.1",
    "jest-environment-jsdom": "^29.4.1",
    "jest-environment-node": "^29.4.1",
    "jest-preset-angular": "14.0.3",
    "jsonc-eslint-parser": "^2.1.0",
    "ng-packagr": "17.3.0",
    "nx": "18.3.1",
    "postcss": "^8.4.5",
    "postcss-import": "~14.1.0",
    "postcss-preset-env": "~7.5.0",
    "postcss-url": "~10.1.3",
    "prettier": "^2.6.2",
    "react-refresh": "^0.10.0",
    "ts-jest": "^29.1.0",
    "ts-node": "10.9.1",
    "typescript": "5.4.5",
    "url-loader": "^4.1.1"
  }
}
