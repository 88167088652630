import { Route } from '@angular/router';

export const routes: Route[] = [
  {
    path: '',
    loadComponent: () => import('./containers/auth-root/auth-root.container'),
    children: [
      {
        path: 'login',
        pathMatch: 'full',
        loadComponent: () => import('./containers/login/login.container'),
      },
      {
        path: 'forgot-password',
        pathMatch: 'full',
        loadComponent: () =>
          import('./containers/forgot-password/forgot-password.container'),
      },
      {
        path: 'reset-password',
        pathMatch: 'full',
        loadComponent: () =>
          import('./containers/reset-password/reset-password.container'),
      },
      {
        path: 'accept-invitation',
        pathMatch: 'full',
        loadComponent: () =>
          import('./containers/accept-invitation/accept-invitation.container'),
      },
    ],
  },
];
