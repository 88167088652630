import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';

// import { RouterFacade } from '@heitown/frontend-routing-utils';

@Component({
  standalone: true,
  imports: [NgIf, RouterOutlet],
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: `
    :host {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      height: 100%;
      background-color: white;
    }

  `,
  template: `
    <!-- partial -->
    <div class="modal-body d-flex flex-column pt-0 h-0 p-3">
      <div class="d-flex flex-column flex-grow-1 h-0" contentAnimate>
        <!-- <do-breadcrumb></do-breadcrumb> -->

        <router-outlet></router-outlet>
      </div>
    </div>
  `,
})
export default class ModalComponent {}
