<div [hidden]="!show" class="loading-spinner-overlay">
  <!-- <mat-spinner color="accent"></mat-spinner> -->
  <div class="sk-folding-cube">
    <div class="sk-cube1 sk-cube"></div>
    <div class="sk-cube2 sk-cube"></div>
    <div class="sk-cube4 sk-cube"></div>
    <div class="sk-cube3 sk-cube"></div>
  </div>

  <div class="text-white fs-125">{{ message() }}</div>
</div>
