import { computed, inject } from '@angular/core';
import {
  EventType,
  Params,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import {
  patchState,
  signalStore,
  withComputed,
  withHooks,
  withMethods,
  withState,
} from '@ngrx/signals';

import {
  EDIT_MODE_PARAM,
  FIND_MODE_PARAM,
  MODAL_LIST_MODE_PARAM,
  VIEW_MODE_PARAM,
} from './constants';

interface BaseRouterStoreState {
  url: string;
}

// interface MinimalActivatedRouteSnapshot {
//   routeConfig: ActivatedRouteSnapshot['routeConfig'];
//   url: ActivatedRouteSnapshot['url'];
//   params: ActivatedRouteSnapshot['params'];
//   queryParams: ActivatedRouteSnapshot['queryParams'];
//   fragment: ActivatedRouteSnapshot['fragment'];
//   data: ActivatedRouteSnapshot['data'];
//   outlet: ActivatedRouteSnapshot['outlet'];
//   title: ActivatedRouteSnapshot['title'];
//   firstChild?: MinimalActivatedRouteSnapshot;
//   children: MinimalActivatedRouteSnapshot[];
// }

export interface MinimalRouterStateSnapshot extends BaseRouterStoreState {
  // root: MinimalActivatedRouteSnapshot;
  params: Params;
  queryParams: Params;
}

// function serializeRoute(
//   route: ActivatedRouteSnapshot
// ): MinimalActivatedRouteSnapshot {
//   const children = route.children.map((c) => serializeRoute(c));

//   return {
//     params: route.params,
//     data: route.data,
//     url: route.url,
//     outlet: route.outlet,
//     title: route.title,
//     routeConfig: route.routeConfig
//       ? {
//           path: route.routeConfig.path,
//           pathMatch: route.routeConfig.pathMatch,
//           redirectTo: route.routeConfig.redirectTo,
//           outlet: route.routeConfig.outlet,
//           title:
//             typeof route.routeConfig.title === 'string'
//               ? route.routeConfig.title
//               : undefined,
//         }
//       : null,
//     queryParams: route.queryParams,
//     fragment: route.fragment,
//     firstChild: children[0],
//     children,
//   };
// }

function serialize(
  routerState: RouterStateSnapshot
): MinimalRouterStateSnapshot {
  let route = routerState.root;

  while (route.firstChild) {
    route = route.firstChild;
  }

  const {
    url,
    root: { queryParams },
  } = routerState;
  const { params } = route;

  // Only return an object including the URL, params and query params
  // instead of the entire snapshot
  return { url, params, queryParams };
}

export interface RouterState extends MinimalRouterStateSnapshot {}

const initialState: RouterState = {
  url: '',
  params: {},
  queryParams: {},
};

export const RouterStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),
  withMethods((state) => {
    return {
      setRouterState: (routerState: MinimalRouterStateSnapshot) => {
        patchState(state, routerState);
      },
    };
  }),
  withHooks({
    onInit(state) {
      const router = inject(Router);
      const routerState = serialize(router.routerState.snapshot);
      state.setRouterState(routerState);
      router.events.subscribe((val) => {
        if (val.type === EventType.NavigationEnd) {
          const routerState = serialize(router.routerState.snapshot);
          state.setRouterState(routerState);
        } else if (val.type === EventType.NavigationStart) {
          const routerState = serialize(router.routerState.snapshot);
          state.setRouterState(routerState);
        }
      });
    },
  }),
  withComputed((state) => ({
    isViewOnlyMode: computed(() => !!state.queryParams()[VIEW_MODE_PARAM]),
    isFindMode: computed(() => !!state.queryParams()[FIND_MODE_PARAM]),
    // isAddMode: computed(() => !!state.queryParams()[ADD_MODE_PARAM]),
    isEditMode: computed(() => !!state.queryParams()[EDIT_MODE_PARAM]),
    isModalListMode: computed(
      () => !!state.queryParams()[MODAL_LIST_MODE_PARAM]
    ),
    channelId: computed(
      () =>
        // state.queryParams()[ADD_MODE_PARAM] ||
        (state.queryParams()[VIEW_MODE_PARAM] ||
          state.queryParams()[FIND_MODE_PARAM] ||
          state.queryParams()[EDIT_MODE_PARAM] ||
          state.queryParams()[MODAL_LIST_MODE_PARAM]) as string | undefined
    ),
  }))
);
