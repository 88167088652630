import { Observable } from 'rxjs';

import { HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { ApiClientConfig } from '@do/app-api-client';

export const apiKeyInterceptor = (
  request: HttpRequest<unknown>,
  next: HttpHandlerFn
): Observable<HttpEvent<any>> => {
  const config = inject(ApiClientConfig);
  if (!request.url.includes(config.apiEndpoint)) {
    return next(request);
  }

  const newHeaders = request.headers.append('x-api-key', config.apiKey);
  const newRequest = request.clone({ headers: newHeaders });
  return next(newRequest);
};
