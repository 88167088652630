/* eslint-disable no-console */
import { first, Observable, switchMap } from 'rxjs';

import {
  HttpEvent,
  HttpHandlerFn,
  HttpInterceptorFn,
  HttpRequest,
} from '@angular/common/http';
import { inject } from '@angular/core';

import { AuthService } from './auth.service';

export const tokenInterceptor: HttpInterceptorFn = (
  request: HttpRequest<unknown>,
  next: HttpHandlerFn
): Observable<HttpEvent<unknown>> => {
  const authService = inject(AuthService);

  return authService.accessToken$.pipe(
    first(),
    switchMap((token) => {
      if (
        !token ||
        request.url.indexOf('/api/') < 0 ||
        request.url.indexOf('/api/auth/refresh') !== -1
      ) {
        return next(request);
      }

      request = request.clone({
        headers: request.headers.set('Authorization', 'Bearer ' + token),
      });

      return next(request);
    })
  );
};

// @Injectable()
// export class AuthInterceptor implements HttpInterceptor {
//   private isRefreshing = false;
//   private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
//     null
//   );

//   constructor(private authService: AuthService) {}

//   private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
//     if (!this.isRefreshing) {
//       this.isRefreshing = true;
//       this.refreshTokenSubject.next(null);

//       return this.authService.refreshTokens().pipe(
//         switchMap((result) => {
//           this.isRefreshing = false;
//           if (!result) {
//             throw new Error('Impossibile ripristinare la sessione. ');
//           }
//           console.log('Refresh token completed - retrying ' + request.url);
//           this.refreshTokenSubject.next(result.accessToken);
//           return next.handle(this.addTokenHeader(request, result.accessToken));
//         }),
//         catchError(() => {
//           this.isRefreshing = false;
//           // this.authStore.sessionExpired();
//           return EMPTY;
//         })
//       );
//     }

//     return this.refreshTokenSubject.pipe(
//       filter((token) => token !== null),
//       take(1),
//       switchMap((token) => next.handle(this.addTokenHeader(request, token)))
//     );
//   }

//   private addTokenHeader(request: HttpRequest<any>, token: string) {
//     /* for Spring Boot back-end */
//     // return request.clone({ headers: request.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + token) });

//     /* for Node.js Express back-end */
//     return request.clone({
//       headers: request.headers.set('Authorization', 'Bearer ' + token),
//     });
//   }
// }
