import { first, map } from 'rxjs';

import { inject } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { CanActivateFn } from '@angular/router';
import { filterNullish } from '@do/app-common';
import { UserDto } from '@do/common-dto';

import { AuthStore } from '../+state/auth.store';

export function loggedUserGuard(): CanActivateFn {
  return () => {
    const authStore = inject(AuthStore);
    return toObservable(authStore.loggedUser).pipe(
      map((loggedUser) => {
        if (!loggedUser) {
          authStore.loadLoggedUser();
        }
        return loggedUser;
      }),
      filterNullish(),
      map((loggedUser: UserDto) => {
        return !!loggedUser;
      }),
      first()
    );
  };
}
