import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IconButtonComponent } from '@do/app-ui-kit';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { EDIT_MODE_PARAM } from './constants';
import { IFrameWrapperComponent } from './iframe-wrapper.component';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    IconButtonComponent,
    IFrameWrapperComponent,
    TranslateModule,
  ],
  template: `
    <div class="modal-header">
      <span class="modal-title fw-bold" translate>{{ title }}</span>

      <span
        class="material-symbols-outlined text-primary cursor-pointer"
        (click)="activeModal.close(null)"
      >
        cancel
      </span>
    </div>
    <div class="flex-grow-1">
      <do-iframe-wrapper
        class="rounded-3"
        [entity]="entity"
        [mode]="mode"
        [id]="id"
        [queryParams]="queryParams"
        (saved)="onMessageReceived($event)"
        (selected)="onMessageReceived($event)"
      ></do-iframe-wrapper>
    </div>
  `,
  styles: [
    `
      :host {
        height: 100%;
        display: flex;
        flex-direction: column;
      }

      .modal-body {
        flex-grow: 1;
      }
    `,
  ],
})
export class ModalWrapperComponent implements OnInit {
  @ViewChild(IFrameWrapperComponent)
  iframe?: IFrameWrapperComponent;

  @Input()
  entity!: string;

  @Input()
  mode!: string;

  @Input()
  id = '';

  @Input()
  title!: string;

  @Input()
  queryParams?: string;

  constructor(
    public activeModal: NgbActiveModal,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    if (!this.entity || !this.mode) throw new Error('mandatory data missing');

    if (!this.title) {
      this.title = this.getTitle(
        this.translateService.instant(this.entity.replaceAll('-', '_'))
      );
    }
  }

  onMessageReceived(event: any) {
    this.iframe?.closeChannel();
    this.activeModal.close(event);
  }

  private getTitle(entityDisplayName: string) {
    const prefix = this.id
      ? this.id != '0'
        ? this.mode === EDIT_MODE_PARAM
          ? this.translateService.instant('Edit')
          : this.translateService.instant('View')
        : this.translateService.instant('Add')
      : this.translateService.instant('Search');
    return `${prefix} ${entityDisplayName}`;
  }
}
