import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
} from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { AuthStore } from '@do/app-auth';
import { MenuItem } from '@do/app-common';
import {
  PERMISSION_ACCESS_ENTITY as ACCESS_CINEMA,
  PERMISSION_READ_ENTITY as READ_CINEMA,
} from '@do/app-domain-cinema';
import { PERMISSION_ACCESS_ENTITY as ACCESS_CINEMA_CHAIN } from '@do/app-domain-cinema-chain';
import { PERMISSION_ACCESS_ENTITY as ACCESS_CINEMA_ROOM } from '@do/app-domain-cinema-room';
import { PERMISSION_ACCESS_ENTITY as ACCESS_CUSTOMER } from '@do/app-domain-customer';
import { PERMISSION_ACCESS_ENTITY as ACCESS_MASTER } from '@do/app-domain-master-control-unit';
import { PERMISSION_ACCESS_ENTITY as ACCESS_USER } from '@do/app-domain-user';
import { SidebarComponent, SidebarService } from '@do/app-ui-kit';
import { Permission } from '@do/common-interfaces';
import { marker } from '@do/ngx-translate-extract-marker';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

import { AboutPopupContainer } from '../components/about-popup.container';

@Component({
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, RouterOutlet, SidebarComponent],
  styles: `

.sidebar-backdrop {
  display: none;
}

    @media screen and (max-width: 1280px){
      .left-sidebar {
        position: absolute !important;
      }

      .content-wrapper {
        margin-left: 74px;
      }

      .sidebar-backdrop {
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        opacity: 0.4;
        background-color: black;
        z-index:4;
      }
    }
  `,
  template: `
    <div class="d-flex h-100 overflow-hidden">
      <do-sidebar
        class="flex-shrink-0 left-sidebar"
        [loggedUser]="authStore.loggedUser()"
        (logout)="authStore.logout()"
        (profile)="authStore.profile()"
        [menuItems]="filteredMenuItems()"
        [title]="title"
        (about)="showAboutPopup()"
      ></do-sidebar>
      <div
        *ngIf="sidebarService.showSidebar()"
        class="sidebar-backdrop"
        (click)="sidebarService.hideSidebar()"
      ></div>
      <div
        class="content-wrapper py-4 px-4 flex-grow-1 d-flex flex-column overflow-auto"
      >
        <router-outlet></router-outlet>
      </div>
    </div>
  `,
})
export default class LayoutComponent {
  translateService = inject(TranslateService);

  sidebarService = inject(SidebarService);

  modal = inject(NgbModal);

  title = 'Smart Cinema Hub';

  menuItems: MenuItem[] = [
    { link: '', label: marker('Dashboard'), icon: 'dashboard' },
    {
      link: 'overview',
      label: marker('Cinema overview'),
      icon: 'mimo',
      permissions: [READ_CINEMA],
    },
    {
      label: marker('Administration'),
      isTitle: true,
    },
    {
      link: 'user',
      label: marker('Users'),
      permissions: [ACCESS_USER],
      icon: 'group',
    },
    {
      link: 'customer',
      label: marker('Customers'),
      permissions: [ACCESS_CUSTOMER],
      icon: 'folder_shared',
    },
    {
      link: 'cinema-chain',
      label: marker('Chains'),
      permissions: [ACCESS_CINEMA_CHAIN],
      icon: 'movie',
    },
    {
      link: 'cinema',
      label: marker('Cinemas'),
      permissions: [ACCESS_CINEMA],
      icon: 'camera_indoor',
    },
    {
      link: 'cinema-room',
      label: marker('Halls'),
      permissions: [ACCESS_CINEMA_ROOM],
      icon: 'living',
    },
    {
      link: 'master-control-unit',
      label: marker('Master control units'),
      permissions: [ACCESS_MASTER],
      icon: 'developer_board',
    },
    {
      link: 'system',
      label: marker('System'),
      permissions: [Permission.SystemAdministration],
      icon: 'manufacturing',
    },
  ];

  authStore = inject(AuthStore);

  filteredMenuItems = computed(() => {
    const permissions = this.authStore.permissions();

    const itemsFilteredByPermission = this.menuItems.filter((mi) =>
      mi.permissions?.length
        ? mi.permissions?.every((perm) => permissions.includes(perm))
        : true
    );

    if (
      itemsFilteredByPermission.at(itemsFilteredByPermission.length - 1)
        ?.isTitle
    ) {
      itemsFilteredByPermission.splice(itemsFilteredByPermission.length - 1, 1);
    }

    return itemsFilteredByPermission;
  });

  showAboutPopup() {
    const modal = this.modal.open(AboutPopupContainer);

    modal.componentInstance.title = this.title;
  }
}
