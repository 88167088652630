<do-breadcrumb
  [breadcrumbItems]="[
    { text: 'Root', link: '/' },
    { text: 'ui kit demo', link: '/ui-kit' }
  ]"
>
</do-breadcrumb>
<div>
  @if (editMode()) {
  <do-form-box [enableSave]="true" (save)="editMode.set(false)">
    <div class="row">
      <div class="col-4 mb-3">
        <do-text-field [label]="'Text field sample'"></do-text-field>
      </div>
      <div class="col-4 mb-3">
        <do-numeric-field label="Numeric field sample"></do-numeric-field>
      </div>
      <div class="col-4 mb-3">
        <do-select-field
          label="Select field sample"
          [items]="[
            { value: 1, description: 'One' },
            { value: 2, description: 'Two' }
          ]"
        ></do-select-field>
      </div>
      <div class="col-4 mb-3">
        <do-autocomplete-field
          [config]="autocompleteConfig"
          label="Autocomplete field sample"
        ></do-autocomplete-field>
      </div>
      <div class="col-4 mb-3">
        <do-check-field label="Check field sample"></do-check-field>
      </div>
      <div class="col-4 mb-3">
        <do-radio-field
          label="Radio field sample"
          [items]="[
            { value: 1, label: 'One' },
            { value: 2, label: 'Two' }
          ]"
        ></do-radio-field>
      </div>
      <div class="col-4 mb-3">
        <do-date-field label="Date field sample"></do-date-field>
      </div>
    </div>
  </do-form-box>
  } @else {
  <do-preview-box (edit)="editMode.set(true)">
    <p>Random data</p>
  </do-preview-box>
  }
</div>

<div class="my-5">
  <h4>Modal</h4>
  <do-button
    text="Launch demo modal"
    cssClasses="btn btn-lg btn-outline-primary"
    (click)="modalService.open(content)"
  ></do-button>
</div>

<ng-template #content let-modal>
  <div class="modal-header">
    <span class="modal-title fw-bold">Title</span>

    <span class="material-symbols-outlined text-primary cursor-pointer">
      cancel
    </span>
  </div>
  <div class="modal-body">
    <p>modal body</p>
  </div>
  <div class="modal-footer">
    <do-button cssClasses="btn-link" text="Cancel"></do-button>
    <do-button cssClasses="btn-primary" text="Footer button"></do-button>
  </div>
</ng-template>
<div class="my-5">
  <h4>Toast</h4>
  <div class="d-flex">
    <do-button
      text="Success toast message title"
      cssClasses="btn btn-success"
      (click)="toastService.successFeedback('Title', 'Message')"
    ></do-button>
    <do-button
      text="Success toast message only"
      cssClasses="btn btn-success ms-2"
      (click)="toastService.successFeedback('Message only')"
    ></do-button>
    <do-button
      text="Error toast message title"
      cssClasses="btn btn-danger ms-2"
      (click)="toastService.errorFeedback('Title', 'Message')"
    ></do-button>
    <do-button
      text="Error toast message only"
      cssClasses="btn btn-danger ms-2"
      (click)="toastService.errorFeedback('Message only')"
    ></do-button>
  </div>
</div>
<div>
  <h1>H1 sample</h1>
  <h2>H2 sample</h2>
  <h3>H3 sample</h3>
  <h4>H4 sample</h4>
  <h5>H5 sample</h5>
  <h6>H6 sample</h6>
</div>

<div>
  <h4 class="mt-5">Buttons</h4>

  <div class="d-flex">
    <span class="me-2">normal with icon</span>
    <do-button
      class="me-2"
      text="Primary"
      cssClasses="btn-primary"
      icon="check"
    ></do-button>
    <do-button
      class="me-2"
      text="Secondary"
      cssClasses="btn-secondary"
      icon="check"
    ></do-button>
    <do-button
      class="me-2"
      text="Dark"
      cssClasses="btn-dark"
      icon="check"
    ></do-button>
    <do-button
      class="me-2"
      text="Light"
      cssClasses="btn-light"
      icon="check"
    ></do-button>
    <do-button
      class="me-2"
      text="Danger"
      cssClasses="btn-danger"
      icon="check"
    ></do-button>
    <do-button
      class="me-2"
      text="Warning"
      cssClasses="btn-warning"
      icon="check"
    ></do-button>
    <do-button
      class="me-2"
      text="Success"
      cssClasses="btn-success"
      icon="check"
    ></do-button>
    <do-button
      class="me-2"
      text="Info"
      cssClasses="btn-info"
      icon="check"
    ></do-button>
  </div>
  <div class="d-flex mt-3">
    <span class="me-2">normal</span>
    <do-button class="me-2" text="Primary" cssClasses="btn-primary"></do-button>
    <do-button
      class="me-2"
      text="Secondary"
      cssClasses="btn-secondary"
    ></do-button>
    <do-button class="me-2" text="Dark" cssClasses="btn-dark"></do-button>
    <do-button class="me-2" text="Light" cssClasses="btn-light"></do-button>
    <do-button class="me-2" text="Danger" cssClasses="btn-danger"></do-button>
    <do-button class="me-2" text="Warning" cssClasses="btn-warning"></do-button>
    <do-button class="me-2" text="Success" cssClasses="btn-success"></do-button>
    <do-button class="me-2" text="Info" cssClasses="btn-info"></do-button>

    <do-button class="me-2" text="Link" cssClasses="btn-link"></do-button>
  </div>
  <div class="d-flex mt-3">
    <span class="me-2">outline</span>
    <do-button
      class="me-2"
      text="Primary"
      cssClasses="btn-outline-primary"
    ></do-button>
    <do-button
      class="me-2"
      text="Secondary"
      cssClasses="btn-outline-secondary"
    ></do-button>
    <do-button
      class="me-2"
      text="Dark"
      cssClasses="btn-outline-dark"
    ></do-button>
    <do-button
      class="me-2"
      text="Light"
      cssClasses="btn-outline-light"
    ></do-button>
    <do-button
      class="me-2"
      text="Danger"
      cssClasses="btn-outline-danger"
    ></do-button>
    <do-button
      class="me-2"
      text="Warning"
      cssClasses="btn-outline-warning"
    ></do-button>
    <do-button
      class="me-2"
      text="Success"
      cssClasses="btn-outline-success"
    ></do-button>
    <do-button
      class="me-2"
      text="Info"
      cssClasses="btn-outline-info"
    ></do-button>
  </div>
  <div class="d-flex mt-3">
    <span class="me-2">sm with icon</span>
    <do-button
      class="me-2"
      text="Primary"
      cssClasses="btn-sm btn-primary"
      icon="check"
    ></do-button>
    <do-button
      class="me-2"
      text="Secondary"
      cssClasses="btn-sm btn-secondary"
      icon="check"
    ></do-button>
    <do-button
      class="me-2"
      text="Dark"
      cssClasses="btn-sm btn-dark"
      icon="check"
    ></do-button>
    <do-button
      class="me-2"
      text="Light"
      cssClasses="btn-sm btn-light"
      icon="check"
    ></do-button>
    <do-button
      class="me-2"
      text="Danger"
      cssClasses="btn-sm btn-danger"
      icon="check"
    ></do-button>
    <do-button
      class="me-2"
      text="Warning"
      cssClasses="btn-sm btn-warning"
      icon="check"
    ></do-button>
    <do-button
      class="me-2"
      text="Success"
      cssClasses="btn-sm btn-success"
      icon="check"
    ></do-button>
    <do-button
      class="me-2"
      text="Info"
      cssClasses="btn-sm btn-info"
      icon="check"
    ></do-button>
  </div>
  <div class="d-flex mt-3">
    <span class="me-2">sm</span>
    <do-button
      class="me-2"
      text="Primary"
      cssClasses="btn-sm btn-primary"
    ></do-button>
    <do-button
      class="me-2"
      text="Secondary"
      cssClasses="btn-sm btn-secondary"
    ></do-button>
    <do-button
      class="me-2"
      text="Dark"
      cssClasses="btn-sm btn-dark"
    ></do-button>
    <do-button
      class="me-2"
      text="Light"
      cssClasses="btn-sm btn-light"
    ></do-button>
    <do-button
      class="me-2"
      text="Danger"
      cssClasses="btn-sm btn-danger"
    ></do-button>
    <do-button
      class="me-2"
      text="Warning"
      cssClasses="btn-sm btn-warning"
    ></do-button>
    <do-button
      class="me-2"
      text="Success"
      cssClasses="btn-sm btn-success"
    ></do-button>
    <do-button
      class="me-2"
      text="Info"
      cssClasses="btn-sm btn-info"
    ></do-button>
  </div>
  <div class="d-flex mt-3">
    <span class="me-2">lg</span>
    <do-button
      class="me-2"
      text="Primary"
      cssClasses="btn-lg btn-primary"
    ></do-button>
    <do-button
      class="me-2"
      text="Secondary"
      cssClasses="btn-lg btn-secondary"
    ></do-button>
    <do-button
      class="me-2"
      text="Dark"
      cssClasses="btn-lg btn-dark"
    ></do-button>
    <do-button
      class="me-2"
      text="Light"
      cssClasses="btn-lg btn-light"
    ></do-button>
    <do-button
      class="me-2"
      text="Danger"
      cssClasses="btn-lg btn-danger"
    ></do-button>
    <do-button
      class="me-2"
      text="Warning"
      cssClasses="btn-lg btn-warning"
    ></do-button>
    <do-button
      class="me-2"
      text="Success"
      cssClasses="btn-lg btn-success"
    ></do-button>
    <do-button
      class="me-2"
      text="Info"
      cssClasses="btn-lg btn-info"
    ></do-button>
  </div>
  <div class="d-flex mt-3">
    <span class="me-2">sm outline</span>
    <do-button
      class="me-2"
      text="Primary"
      cssClasses="btn-sm btn-outline-primary"
    ></do-button>
    <do-button
      class="me-2"
      text="Secondary"
      cssClasses="btn-sm btn-outline-secondary"
    ></do-button>
    <do-button
      class="me-2"
      text="Dark"
      cssClasses="btn-sm btn-outline-dark"
    ></do-button>
    <do-button
      class="me-2"
      text="Light"
      cssClasses="btn-sm btn-outline-light"
    ></do-button>
    <do-button
      class="me-2"
      text="Danger"
      cssClasses="btn-sm btn-outline-danger"
    ></do-button>
    <do-button
      class="me-2"
      text="Warning"
      cssClasses="btn-sm btn-outline-warning"
    ></do-button>
    <do-button
      class="me-2"
      text="Success"
      cssClasses="btn-sm btn-outline-success"
    ></do-button>
    <do-button
      class="me-2"
      text="Info"
      cssClasses="btn-sm btn-outline-info"
    ></do-button>
  </div>
</div>
<h4 class="mt-5">DataGrid</h4>
<div style="height: 400px" class="mt-3 d-flex">
  <do-data-grid
    [columnDefs]="coldef"
    [items]="data"
    [canEdit]="true"
  ></do-data-grid>
</div>
