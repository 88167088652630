/* eslint-disable @typescript-eslint/no-empty-function */
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';

import { DatepickerComponent } from '../../core/datepicker/datepicker.component';
import { BaseFormFieldComponent } from '../base-form-field.component';
import { FormFieldWrapperComponent } from '../form-field-wrapper.component';

@Component({
  selector: 'do-date-field',
  template: `
    <do-form-field-wrapper
      [label]="label"
      [shouldShowError]="shouldShowError()"
      [errors]="errors()"
      [tooltip]="tooltip"
    >
      <do-datepicker
        [isDatetime]="isDatetime"
        [hasError]="shouldShowError()"
        [formControl]="formControl"
        (blur)="onBlur()"
        [readOnly]="readOnly"
      >
      </do-datepicker>
    </do-form-field-wrapper>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateFieldComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormFieldWrapperComponent,
    ReactiveFormsModule,
    DatepickerComponent,
  ],
})
export class DateFieldComponent extends BaseFormFieldComponent {
  @Output()
  // eslint-disable-next-line @angular-eslint/no-output-native
  blur = new EventEmitter();

  @Input()
  isDatetime = false;

  onBlur() {
    this.blur.emit();
  }
}
