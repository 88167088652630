import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';

// import { RouterFacade } from '@heitown/frontend-routing-utils';

@Component({
  standalone: true,
  imports: [RouterOutlet],
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: `
    :host {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      height: 100%;
      background-color: white;
    }

  `,
  template: ` <router-outlet></router-outlet> `,
})
export default class IFrameComponent {}
